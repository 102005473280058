// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bJ_kz";
export var caseStudyBackground__lineColor = "bJ_kq";
export var caseStudyHead = "bJ_kC";
export var caseStudyHead__imageWrapper = "bJ_kn";
export var caseStudyProjectsSection = "bJ_kB";
export var caseStudyQuote__bgLight = "bJ_kx";
export var caseStudyQuote__bgRing = "bJ_kw";
export var caseStudyVideoReview = "bJ_lz";
export var caseStudyVideoReview__bgRing = "bJ_lB";
export var caseStudyVideo__ring = "bJ_kF";
export var caseStudy__bgDark = "bJ_km";
export var caseStudy__bgLight = "bJ_kl";
export var caseStudy__bgLightReverse = "bJ_ly";